const content = {
  selectedWorks: [
    {
      title: "Spirē",
      url: "https://getspire.io",
      description: `Spirē is a SAAS that empowers businesses and their product teams
      to connect and learn what
      their customers want through feedback, enabling them to build better products their customers will love.  Built the feedjet feature - an embeddable widget that businesses can use to collect product related
      feedback on their web applications.`,
      stack: [
        "javascript", "preact", "css-modules"
      ]
    },
    {
      title: "Cribstack",
      url: "https://cribstack.com",
      description: `Finding the right home to rent or purchase in Lagos is a hassle, but it doesn't have to be. Cribstack follows you through your house-hunting journey and makes it easier to find homes, roommates or flatmates that match your vibe`,
      stack: [
        "typescript", "react", "react-query", "bootstrap"
      ]
    },
    {
      title: "Realturnup",
      url: "https://admin.realturnup.com/",
      description: `Realturnup is Nigeria’s largest bidding and auction Platform. I worked on the admin portal for creating, uploading and managing products and bids`,
      stack: [
        "typescript", "react", "chakraui", "scss", "react-query"
      ]
    },
    {
      title: "Draftboxe",
      url: "https://draftboxe.netlify.app/",
      description: `Inspiration strikes anywhere. Draftboxe lets you capture, organize and share your ideas across any device in form of notes.`,
      stack: [
        "typescript", "next.js", "tailwind-css", "supabase"
      ]
    },
    {
      title: "Flance",
      url: "https://flance.netlify.app/",
      description: `A freelance management solution.
      Built the UI components using atomic design architecture from the landing page to the onboarding process and the dashboard.`,
      stack: [
        "typescript", "next.js", "styled-components", "framer-motion"
      ]
    },
    {
      title: "Fyurr",
      url: "https://github.com/BarnabasAsha/fyyur",
      description: `Fyyur is a musical venue and artist booking site that facilitates the discovery and bookings of shows between local performing artists and venues. This site lets you list new artists and venues, discover them, and list shows with artists as a venue owner. This project involved building out the data models to power the API endpoints for the Fyyur site by connecting to a PostgreSQL database for storing, querying, and creating information about artists and venues on Fyyur`,
      stack: [
        "python", "flask", "postgresql"
      ]
    },
    {
      title: "PetMobi",
      url: "https://petmobi.netlify.app",
      description: `Landing page for a dog care website`,
      stack: [
        "vue", "scss"
      ]
    },
    {
      title: "Pixeler",
      url: "https://pixeler.netlify.app/",
      description: `Search, View and Explore free and high resolution images. Built with unsplash api`,
      stack: [
        "vue", "scss", "unsplash-api"
      ]
    }
  ],
  certifications: [
    {
      title: "UDACITY FULLSTACK DEVELOPER NANODEGREE",
      url: "https://www.udacity.com/course/full-stack-web-developer-nanodegree--nd0044",
      description: `This nanodegree extensively covered various technical skills required to build
      database-backed APIs and
      web applications.`
    }
  ]
}

export default content;