import content from "./content"

// Handling color mode switches
const root = document.querySelector(':root')
const lightSwitch = document.querySelector('.port--switch_light')
const darkSwitch = document.querySelector('.port--switch_dark')

const lightColor = '#ffdf99'
const darkColor = '#80dfa6'

lightSwitch.addEventListener('click', () => {
  root.style.setProperty('--bg-color', lightColor)
})

darkSwitch.addEventListener('click', () => {
  root.style.setProperty('--bg-color', darkColor)
})

// Load Data


const loadSelectedWorks = () => {
  const workList = document.querySelector('.port--work')
  content.selectedWorks.forEach((item, index) => {
    const listItem = document.createElement('li')
    listItem.innerHTML = `
    <li class="port--list">
              <a href="${item.url}" target="_blank" class="port--list_title">${(index + 1) < 10 ? `0${index + 1}.` : `${index + 1}.`} ${item.title}</a>
              <p class="port--list_text">${item.description}
              </p>
              <ul class="port--list_stack">
              ${item.stack.map((tool, index) => (
                `<li>${tool} ${(item.stack.length - 1 !== index) ? '+' : ''}</li>`
              )).join('')}
              </ul>
            </li>
    `
    workList.appendChild(listItem)

  })
}

const loadCertifications = () => {
  const certificationsList = document.querySelector('.port--certifications')
  content.certifications.forEach((item) => {
    const listItem = document.createElement('li')
    listItem.innerHTML = `
    <li class="port--list">
              <a href="${item.url}" target="_blank" class="port--list_title">${item.title}</a>
              <p class="port--list_text">${item.description}
              </p>
            </li>
    `
    certificationsList.appendChild(listItem)

  })
}

const cursor = document.getElementById("port--cursor");

document.body.addEventListener("mousemove", function(e) {
  cursor.style.left = e.clientX + "px",
    cursor.style.top = e.clientY + "px";
});



const checkIfMobile = () => {
  const isMobile = (/Android|webOS|iPhone|iPad|iPod|iPad Air|iPad Mini|BlackBerry/i.test(navigator.userAgent) ||
  (/Android|webOS|iPhone|iPad|iPod|iPad Air|iPad Mini|BlackBerry/i.test(navigator.platform)))

  if(isMobile) {
    cursor.style.display = 'none'
  }
}

window.addEventListener('load', () => {
  loadSelectedWorks()
  loadCertifications()
  checkIfMobile()
})


